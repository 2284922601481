import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SponsorService {

  constructor() {}

  private podcastsSponsors: any = {
    328612: {
      name: 'Ford / Structube',
      url: '',
      logos: {
        rgb:
          'https://cdn.cogecolive.com/websites-public/Balado_Commandite_GIF_Ford_Structube_Profab_RGB.gif',
        reversed:
          'https://cdn.cogecolive.com/websites-public/Balado_Commandite_GIF_Ford_Structube_Profab_Renv.gif',
      },
    },
    659618: {
      name: 'SOS violence conjugale',
      url: '',
      logos: {
        rgb:
          'https://cdn.cogecolive.com/websites-public/Balado_Commandite_SOS-violence-conjugale_RGB.png',
        reversed:
          'https://cdn.cogecolive.com/websites-public/Balado_Commandite_SOS-violence-conjugale_Renv.png',
      },
    },
  };

  public getPodcastSponsor(podcastId: number) {
    if (this.podcastsSponsors.hasOwnProperty(podcastId)) {
      return this.podcastsSponsors[podcastId];
    }
  }

  public getPodcastSponsorLogo(podcastId: number, type: string) {
    if (this.podcastsSponsors.hasOwnProperty(podcastId)) {
      return this.podcastsSponsors[podcastId].logos[type];
    }
  }
}
