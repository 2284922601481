import { isPlatformServer, Location } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CategoryService } from './category.service';
import { StationService } from './station.service';

@Injectable()
export class VideoAdsService {
  public changeStatusSubject: BehaviorSubject<any> = new BehaviorSubject('NO ADS');
  private dfpAccountId: string = '21658289790,206798719';
  private adsLayer: any;
  private videoAdContainer: any;
  private adDisplayContainer: any;
  private adsLoader: any;
  private adsRequest: any;
  private adsManager: any;
  private videoEndedCallback: any;
  private keyValue: string;
  private adsRequestedCount: number = 1;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private stationService: StationService,
    private location: Location,
    private categoryService: CategoryService,
  ) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
  }
  public setupAds() {
    this.stationService.waitUntilReady(() => {
      if (typeof google !== 'undefined') {
        google.ima.settings.setLocale(this.stationService.station.language);
        if (this.isIOS10plus()) {
          // must be set before creating the container
          google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);
        }
        this.createAdDisplayContainer();

        this.adsLoader = new google.ima.AdsLoader(this.adDisplayContainer);
        this.adsLoader.getSettings().setAutoPlayAdBreaks(true);
        // Listen and respond to ads loaded and error events.
        this.adsLoader.addEventListener(
          google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
          this.onAdsManagerLoaded.bind(this),
          false
        );
        this.adsLoader.addEventListener(
          google.ima.AdErrorEvent.Type.AD_ERROR,
          this.onAdError.bind(this),
          false
        );
      }
    });
  }

  public destroyAdsManager() {
    if (this.adsManager) {
      this.adsManager.destroy();
      this.adsManager = null;
    }
  }

  public requestVideoAds(callback: any, type: string, adRule: number = 1, info?: any) {
    this.videoEndedCallback = callback;

    this.adsRequestedCount++;
    if (this.adsRequestedCount % 1 === 0) {
      this.keyValue = this.getAudioKeyValues(info);
      this.adDisplayContainer.initialize();
      this.adsRequest = new google.ima.AdsRequest();
      this.adsRequest.adTagUrl = this.getAdTagUrl(type, adRule) + this.keyValue;
      console.info('preroll setting', { tag: this.getVideoAdSlot(type), keyValue: this.keyValue });

      if (madops && madops.targetVastUrl) {
        this.adsRequest.adTagUrl = madops.targetVastUrl(this.adsRequest.adTagUrl);
      }
      this.adsLoader.requestAds(this.adsRequest);
    } else {
      this.onContentResumeRequested();
    }
  }

  public getVideoAdSlot(type: string) {
    if (!this.stationService.station || !this.stationService.station.aiirAdTag) {
      return;
    }
    if (type) {
      return '/' + this.dfpAccountId + '/' + this.stationService.station.aiirAdTag + '/' + type;
    } else {
      return '/' + this.dfpAccountId + '/' + this.stationService.station.aiirAdTag;
    }
  }

  public getAdTagUrl(type: string, adRule: number = 1) {
    return (
      'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=' +
      this.getVideoAdSlot(type) +
      '&ciu_szs=1x1,300x250,300x600,320x50,728x90,970x250&impl=s&gdfp_req=1&ad_type=audio_video&env=instream&output=vast&unviewed_position_start=1&plcmt=1&url=[referrer_url]&description_url=' +
      this.encodeKeyValues(window.location.href) +
      '&correlator=[timestamp]&ad_rule=' +
      adRule
    );
  }

  private createAdDisplayContainer() {
    this.adsLayer = document.getElementById('video-ad-container');
    this.videoAdContainer = document.getElementById('video-ad');
    this.adDisplayContainer = new google.ima.AdDisplayContainer(
      this.adsLayer,
      document.getElementById('video-ad-element')
    );
  }

  private onAdError(adErrorEvent: any) {
    console.log('error video ads', adErrorEvent.getError());
    this.hideAds();
    if (this.adsManager) {
      this.adsManager.destroy();
    }
    this.changeStatusSubject.next('AD ERROR');
    this.videoEndedCallback();
  }

  private onContentResumeRequested() {
    this.hideAds();
    this.videoEndedCallback();
  }

  private onAdsManagerLoaded(adsManagerLoadedEvent: any) {
    const adsRenderingSettings = new google.ima.AdsRenderingSettings();
    adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
    this.adsManager = adsManagerLoadedEvent.getAdsManager(
      document.getElementById('audio-streaming'),
      adsRenderingSettings
    );

    this.adsManager.addEventListener(
      google.ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdError.bind(this)
    );
    this.adsManager.addEventListener(
      google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
      this.onContentResumeRequested.bind(this)
    );
    this.adsManager.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, () => {
      this.hideAds();
      this.changeStatusSubject.next('ALL_ADS_COMPLETED');
    });
    this.adsManager.addEventListener(google.ima.AdEvent.Type.LOADED, (adEvent: any) => {
      this.displayAds();
      this.changeStatusSubject.next('AD LOADED');
    });
    this.adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, () => {
      this.changeStatusSubject.next('AD PLAYING');
    });
    this.adsManager.addEventListener(google.ima.AdEvent.Type.PAUSED, () => {
      this.adsManager.resume();
    });
    this.adsManager.addEventListener(google.ima.AdEvent.Type.CLICK, () => {
      this.adsManager.resume();
    });
    this.adsManager.addEventListener(google.ima.AdEvent.Type.COMPLETE, () => {
      this.hideAds();
      this.changeStatusSubject.next('AD ENDED');
    });
    this.adsManager.addEventListener(google.ima.AdEvent.Type.SKIPPED, () => {
      this.hideAds();
      this.changeStatusSubject.next('AD SKIPPED');
    });

    try {
      this.adsManager.init(640, 360, google.ima.ViewMode.NORMAL);
      this.adsManager.start();
    } catch (adError) {
      this.videoEndedCallback();
    }
  }

  private hideAds() {
    if (this.adsLayer) {
      this.videoAdContainer.style.display = 'none';
    }
  }

  private displayAds() {
    this.videoAdContainer.style.display = 'block';
  }

  private getAudioKeyValues(info: any): string {
    let keyValue: string = '';
    let hostsKeyValue: string = '';
    let showsKeyValue: string = '';
    let categoryKeyValue: any;
    const url: string = this.location
      .path()
      .toLowerCase()
      .split(/[?#]/)[0];

    if (url) {
      keyValue += 'url=' + url;
    } else {
      keyValue += 'url=/';
    }

    if (info && info.hosts && info.hosts instanceof Array) {
      hostsKeyValue = this.getKeyValueString(info.hosts);
    }

    if (info && info.shows && info.shows instanceof Array) {
      showsKeyValue = this.getKeyValueString(info.shows);
    }

    if (info && info.category) {
      categoryKeyValue = this.categoryService.getCategoryDetailFromCategory(info.category);
    }

    if (hostsKeyValue) {
      keyValue += (keyValue ? '&' : '') + 'IN=' + hostsKeyValue;
    }

    if (showsKeyValue) {
      keyValue += (keyValue ? '&' : '') + 'EM=' + showsKeyValue;
    }

    if(categoryKeyValue) {
      if(categoryKeyValue.childCategory) {
        keyValue += (keyValue ? '&' : '') + 'S-CAT=' + categoryKeyValue.childCategory.slugFr;
      }
      if(categoryKeyValue.parentCategory) {
        keyValue += (keyValue ? '&' : '') + 'CAT=' + categoryKeyValue.parentCategory.slugFr;
      }
    }

    if (info && info.podcastId) {
      keyValue += (keyValue ? '&' : '') + 'PODCAST=podcast-' + info.podcastId;
    }

    if(info?.type == "podcastepisode") {
      keyValue += (keyValue ? '&' : '') + 'EPISODE=episode-' + info.id;
    }

    if (info && info.segment?.id) {
      keyValue += (keyValue ? '&' : '') + 'SEGMENT=segment-' + info.segment.id;
    }

    if (keyValue) {
      keyValue = '&cust_params=' + this.encodeKeyValues(keyValue);
    }
    return keyValue;
  }

  private getKeyValueString(keyValues: any[]): string {
    let keyValueString = '';
    keyValues.forEach((keyValue, index) => {
      if (keyValue.slug) {
        if (typeof keyValue.slug === 'string') {
          keyValueString += (keyValueString ? ',' : '') + keyValue.slug;
        } else {
          keyValueString += (keyValueString ? ',' : '') + keyValue.slug[this.stationService.station.language];
        }
      } else if (keyValue.name) {
        if (typeof keyValue.slug === 'string') {
          keyValueString += (keyValueString ? ',' : '') + keyValue.name;
        } else {
          keyValueString += (keyValueString ? ',' : '') + keyValue.name[this.stationService.station.language];
        }
      }
    });
    return keyValueString;
  }

  private encodeKeyValues(keyValue: string): string {
    let newKeyValue = keyValue.split('&').join('%26');
    newKeyValue = newKeyValue.split(':').join('%3A');
    newKeyValue = newKeyValue.split('/').join('%2F');
    newKeyValue = newKeyValue.split('=').join('%3D');
    newKeyValue = newKeyValue.split(',').join('%2C');
    return newKeyValue;
  }

  private isIOS10plus(): boolean {
    if (isPlatformServer(this.platformId)) {
      return false;
    }
    return (
      /(iPad|iPhone|iPod)(.*)(OS )([1-9]\d|\d{3,})/.test(navigator.userAgent) &&
      !(window as any).MSStream
    );
  }
}
